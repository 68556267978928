import React from "react";
import { RiEdit2Fill, RiRefreshLine } from "react-icons/ri";
import { Button, Table } from "reactstrap";
import AreaMensajes from "../Modales/AreaMensajes";
import { BotonesVerEditar } from "../Panel/BotonesVerEditar";
import Nabvar from "../Panel/Nabvar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { configuracion_estadodecuenta } from "../../Services/Api";
import { ordenarservicios } from "../Catalogos/Complementos/Ordenamientos";
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import ModalParametrosSistema from "../Modales/ModalParametrosSistema";
import { Redirect } from "react-router-dom";

export default class ParametrosDeSistemaNuevo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Parámetro de sistema",
            botoncrear: "Crear nueva cuenta",
            showmensaje: false,
            vermodalcambio: false,
            permisoedicion: false,
            titulomensaje: "",
            descripcionmensaje: "",
            tabla: {
                encabezados: [
                    "Folio",
                    "Parámetro",
                    "Valor",
                    "Descripción",
                    "",

                ],
                dimensiones: [

                    "10%",
                    "10%",
                    "10%",
                    "50%",
                    "7%",
                    "7%",

                ],
                mindimensiones: [
                    "60px",
                    "60px",
                    "90px",
                    "90px",
                    "120px",
                    "110px",

                ],
                elementos: []
            },
            arraydatos: [],
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }
    modalcambio = (item) => {
        this.setState({ arraydatos: JSON.parse(JSON.stringify(item)) })
        this.setState({ vermodalcambio: !this.state.vermodalcambio })
    }

    cerrarmodal = () => {

        this.setState({ vermodalcambio: !this.state.vermodalcambio })
        this.refreshInfo();
    }

    refreshInfo = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        const lista = await configuracion_estadodecuenta();

        if (lista.code == 0) {
            let listaitems = this.state.tabla;
            listaitems.elementos = lista.data.items;

            this.setState({ tabla: listaitems, actualordenado: "", showmensaje: false })
            //verificar permisos
            if (lista.data.sololectura == 1) {
                this.setState({ permisoedicion: false });
            }
            else {
                this.setState({ permisoedicion: true });
            }
        }
        else {
            if (lista.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        await this.refreshInfo();
    }


    ordenar = (opcion) => {
        let actual = this.state.tabla;
        let x = ordenarservicios(opcion, actual, this.state.actualordenado);
        actual.elementos = x;
        this.setState({ tabla: actual, actualordenado: opcion });
    }


    crearelemento = (item) => { }

    render() {
        return (
            <>

                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                <ToastContainer />


                {(this.state.vermodalcambio == true) ? (
                    <ModalParametrosSistema valores={this.state.arraydatos} show={this.state.vermodalcambio} changestate={this.cerrarmodal} />
                ) : null}

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px", minWidth: "1100px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body ">

                        <div className="panel-body-header-table">
                            {this.state.titulotabla}
                            <div className="panel-body-header-table-buttons ">
                                <div>
                                    <Button className="greenoutlinebutton" onClick={this.refreshInfo}><RiRefreshLine /> Refrescar Lista</Button>
                                </div>
                            </div>
                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :

                            <>
                                {(this.state.tabla.elementos.length > 0) ? (
                                    <>
                                        <Table className="panel-table" style={{ fontSize: "14px" }} >
                                            <thead>
                                                <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (

                                                        <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                            style={{ width: this.state.tabla.dimensiones[index], minWidth: this.state.tabla.mindimensiones[index] }}
                                                            onClick={() => this.ordenar(index)}>

                                                            {titulo}

                                                        </th>

                                                    ))}
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.tabla.elementos.map((item, index) => (


                                                    <tr key={"tablaitemtr" + index}>
                                                        <td className={"panel-table-cell" + 0}>
                                                            {item.idparametrossistema}
                                                        </td>
                                                        <td className={"panel-table-cell" + 1}>
                                                            {item.parametro}
                                                        </td>
                                                        <td className={"panel-table-cell" + 2}>
                                                            {item.valor}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.descripcion}
                                                        </td>

                                                        <td className="panel-table-buttons">

                                                            <Button style={{ minWidth: "84px" }}
                                                                className={(this.state.permisoedicion) ? ("bluebutton") : "cancelbutton"}
                                                                disabled={(this.state.permisoedicion) ? (false) : true}

                                                                onClick={(this.state.permisoedicion) ? (
                                                                    this.modalcambio.bind(this, item)
                                                                ) : (
                                                                    () => console.log("no tienes permiso")
                                                                )
                                                                }>
                                                                <RiEdit2Fill /> Editar</Button>

                                                        </td>
                                                    </tr>


                                                ))}

                                            </tbody>
                                        </Table>

                                    </>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }

                            </>
                        }

                    </div>

                    <div className="panel-footer">
                        <label>  © VECTOR, Casa de Bolsa. Derechos Reservados. 2024</label>
                    </div>
                </div>

            </>
        )
    }
}
