import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import { createBrowserHistory } from 'history';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './Componentes/Home/Home';

import Ajustes from './Componentes/Cuenta/Ajustes';
import Password from './Componentes/Cuenta/Password';

import Usuarios2 from './Componentes/Usuarios/Usuarios2';

import BitacoraNotificacion from './Componentes/Administracion/BitacoraNotificacion';

import Situacion2 from './Componentes/Catalogos/Situacion2';
import Errores2 from './Componentes/Catalogos/Errores2';
import TipoNotificacion from './Componentes/Catalogos/TipoNotificacion';
import Clientes from './Componentes/Catalogos/Clientes';
import ParametrosDeSistemaNuevo from './Componentes/Administracion/ParametrosDeSistemaNuevo';
import Monedas2 from './Componentes/Catalogos/Monedas2';
import PromotoresServicios from './Componentes/Catalogos/PromotoresServicios';

{/* 
import Cuentas2 from './Componentes/Administracion/Cuentas2';
import TipoOperacion2 from './Componentes/Catalogos/TipoOperacion2';
import MediosServicios2 from './Componentes/Catalogos/MediosServicios2';
import Estadodecuenta from './Componentes/Catalogos/EstadoCuenta';
import CuentasAsociadas from './Componentes/Administracion/CuentasAsociadas';
import EnvioResultados2 from './Componentes/Catalogos/EnvioResultados2';
import Servicios2 from './Componentes/Catalogos/Servicios2';
*/}

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>

      <Switch>
     
        {/* cuenta */}
        <Route path="/password" component={Password} />
        <Route path="/ajustes" component={Ajustes} />

        {/* usuarios */}
        <Route path="/usuarios" component={Usuarios2} />

        {/* operación */}
        <Route path="/bitacoranotificacion" component={BitacoraNotificacion} />

        {/* catálogos */}
        <Route path="/situacion" component={Situacion2} />
        <Route path="/errores" component={Errores2} />       
        <Route path="/notificaciones" component={TipoNotificacion} />

        <Route path="/clientes" component={Clientes} />
        <Route path="/parametrodesistema" component={ParametrosDeSistemaNuevo} />
        <Route path="/monedas" component={Monedas2} />
        <Route path="/promotores" component={PromotoresServicios} />


{/* 
        <Route path="/estadosdecuenta" component={CuentasAsociadas} />{/* //Arias/ * /}
        <Route path="/cuentas" component={Cuentas2} />
        <Route path="/tipoestadodecuenta" component={Estadodecuenta} />{/* //Arias/ * /}
        <Route path="/tipooperacion" component={TipoOperacion2} />
        <Route path="/mediosservicio" component={MediosServicios2} />
        <Route path="/servicios" component={Servicios2} />
        <Route path="/envioresultados" component={EnvioResultados2} />
*/}
        <Route path="/" component={Home} />
      </Switch>


    </BrowserRouter>
  );

}

export default App;

