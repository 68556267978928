import React from "react";
import MenuItem, { MenuMovil } from "./MenuItem";
import { AiOutlineMenu } from 'react-icons/ai';
import { Redirect } from "react-router-dom";
import { AiFillHome } from 'react-icons/ai'

export default class Nabvar extends React.Component {

    constructor(props) {
        super(props);
        var loca = window.location.pathname;
        var ara = loca.split('/');


        this.state = {
            ls: "",
            rutaactual: "/" + ara[1],
            menumovilopen: false,
            menu: [
                {
                    titulo: "Inicio",
                    activa: false,
                    items: []
                },
                {
                    titulo: "Catálogos",
                    activa: false,
                    items: [ 
                        { nombre: "Tipo de Situación", enlace: "/situacion" },
                        { nombre: "Tipo de Error", enlace: "/errores" },
                        { nombre: "Tipo de Notificación", enlace: "/notificaciones" },
                        { nombre: "Parámetro de sistema", enlace: "/parametrodesistema" },
                        { nombre: "Catálogo de Promotores", enlace: "/promotores" },
                        { nombre: "Catálogo de Clientes", enlace: "/clientes" },
                         { nombre: "Monedas", enlace: "/monedas" },

                       /* { nombre: "Envío de resultados", enlace: "/envioresultados" },
                        { nombre: "Servicios", enlace: "/servicios" },
                       
                       
                        { nombre: "Medios de servicio", enlace: "/mediosservicio" },
                        { nombre: "Asesores de servicio", enlace: "/asesoresservicio" },
                        { nombre: "Familia", enlace: "/familia" },
                        { nombre: "Tipo de operación", enlace: "/tipooperacion" },
                        { nombre: "Tipo de estado de cuenta", enlace: "/tipoestadodecuenta" },*/
                    ]
                },
                {
                    titulo: "Operación",
                    activa: false,
                    items: [
                        { nombre: "Bitácora de Notificación", enlace: "/bitacoranotificacion" },
                        /*  { nombre: "Bitácora de Conciliación", enlace: "/bitacoranotificacion" },
                      { nombre: "Cuentas", enlace: "/cuentas" },
                        { nombre: "Estado de cuenta", enlace: "/estadosdecuenta" },                      
                        { nombre: "Bitácora de gestión", enlace: "/bitacoragestion" },
                        { nombre: "Parámetro de sistema", enlace: "/parametrodesistema" },*/
                    ]
                },
                /*{
                    titulo: "Reportes",
                    activa: false,
                    items: [
                        { nombre: "Consulta archivos recibidos", enlace: "/consultararchivos" },
                        { nombre: "Tipo de servicio por medio y cuenta", enlace: "/consultarclientes" },
                        { nombre: "Volumen Operado", enlace: "/volumenoperado" },
                    ]
                },*/
                {
                    titulo: "Usuarios",
                    activa: false,
                    items: [
                        { nombre: "Gestión de usuarios", enlace: "/usuarios" },
                    ]
                },

                {
                    titulo: "Cuenta",
                    activa: false,
                    items: [
                        { nombre: "Ajustes", enlace: "/ajustes" },
                        { nombre: "Contraseña", enlace: "/password" },
                        { nombre: "Cerrar sesión", enlace: "/" },
                    ]
                },

            ],
            seleccionado: false
        }
    }

    componentDidMount() {

        if (sessionStorage.getItem("ls")) {
            let ultimomovimiento = new Date().toLocaleTimeString();
            sessionStorage.setItem("ultimomovimiento", convertirtiempo(ultimomovimiento));

            iniciarcuentaregresiva();
        }


    }





    render() {
        return (
            <>
                {(this.state.seleccionado) ? (<Redirect to={"/"} />) : null}

                {(window.innerWidth > 1260) ? (
                    <div className="navbar-web">
                        <img className="navbar-web-logo" src="assets/logos/empresa_logob.png" />
                        <div className="navbar-web-menu">


                            <div className="navbar-web-menuitem navbar-web-menuitem-button " onClick={() => this.setState({ seleccionado: true })}>
                                <AiFillHome className="navbar-web-menuicons" /> {this.state.menu[0].titulo}
                            </div>

                            {(sessionStorage.getItem("ls")) ? (
                                <>
                                    <div className="navbar-web-menuitem">
                                        <MenuItem grupo={this.state.menu[1]} icon={1} ruta={this.state.rutaactual} />
                                    </div>
                                    <div className="navbar-web-menuitem">
                                        <MenuItem grupo={this.state.menu[2]} icon={2} ruta={this.state.rutaactual} />
                                    </div>
                                    <div className="navbar-web-menuitem">
                                        <MenuItem grupo={this.state.menu[3]} icon={3} ruta={this.state.rutaactual} />
                                    </div>
                                    <div className="navbar-web-menuitem">
                                        <MenuItem grupo={this.state.menu[4]} icon={4} ruta={this.state.rutaactual} />
                                    </div>
                                   
                                </>

                            ) : null}

                        </div>

                    </div>
                ) :
                    <div className="navbar-movil">
                        <div className="navbar-movil-menu">
                            <img className="navbar-movil-logo" src="assets/logos/empresa_logob.png" />
                            <div className="navbar-movil-menuitem">
                                <button onClick={() => this.setState({ menumovilopen: !this.state.menumovilopen })} >
                                    <AiOutlineMenu />
                                </button>
                            </div>
                        </div>
                        <MenuMovil colapsar={this.state.menumovilopen} listamenu={this.state.menu} ruta={this.state.rutaactual} />
                    </div>
                }</>
        )
    }
}





export function inicializarconteoregresivo() {
    //console.log("conteoregresivo");
    let ultimomovimiento = new Date().toLocaleTimeString();
    sessionStorage.setItem("ultimomovimiento", convertirtiempo(ultimomovimiento));
}

export function convertirtiempo(tiempoacambiar) {
    let tiempos = tiempoacambiar.split(":");
    let horas = (parseInt(tiempos[0]) * 60) * 60;
    let minutos = (parseInt(tiempos[1]) * 60);
    let segundos = parseInt(tiempos[2]);

    let suma = horas + minutos + segundos;
    return suma;
}



function paddedFormat(num) {
    return num < 10 ? "0" + num : num;
}

export function iniciarcuentaregresiva() {
    try {

        let countInterval = setInterval(function () {
            let ultimomovimiento = sessionStorage.getItem("ultimomovimiento");
            let tiempoactual = new Date().toLocaleTimeString();
            let tiempotranscurrido = (convertirtiempo(tiempoactual)) - ultimomovimiento;
            //  console.log("tiempo transcurrido: ", tiempotranscurrido);
            // console.log("ultimomovimiento", ultimomovimiento);
            // console.log("tiempoactual", tiempoactual)

            try {

                if (tiempotranscurrido > 420) {
                    clearInterval(countInterval);
                    //console.log("tiempo agotado");
                    sessionStorage.removeItem("ls");

                    var loca = window.location.pathname;
                    var ara = loca.split('/');
                    ara = "/" + ara[1]

                    window.location.reload(true)

                }
            } catch (error) {
                clearInterval(countInterval);
            }
        }, 100);
    } catch (error) {

    }
}