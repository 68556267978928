import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import { gestion_editarusuario, info_perfil } from "../../Services/Api";
import { validarpersonanombre, validarEmail } from "../../Services/Validaciones";
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import Nabvar from "../Panel/Nabvar";
import AreaMensajes from "../Modales/AreaMensajes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoX } from "react-icons/go";
import Inputform from "../Inputform/Inputform";
import { RiRefreshLine } from "react-icons/ri";


export default class Ajustes extends React.Component {

    constructor(props) {
        super(props)

        let usuario = sessionStorage.getItem("ls");
        this.state = {
            altura: window.innerHeight,
            anchura: window.innerWidth,
            titulotabla: "Ajustes",

            confirmarenvio: false,
            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            inputsform: [

                {
                    tituloinput: "Nombre:", contenido: "", tipoinput: "text", posicion: 1,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },
                {
                    tituloinput: "Apellidos:", contenido: "", tipoinput: "text", posicion: 2,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },
                {
                    tituloinput: "Correo electrónico:", contenido: "", tipoinput: "text", posicion: 3,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },
                {
                    tituloinput: "Login:", contenido: "", tipoinput: "vista", posicion: 4,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },

                {
                    tituloinput: "Roles:", contenido: "1", tipoinput: "vista", posicion: 5,
                    validacion: "", mensajevalidacion: "erro de descripción", disabled: false, valor: ""
                },
            ],
            loadingImage: false,
            cancelar: false,

        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refresh = async () => {
        this.setState({ loadingImage: true, showmensaje: false })

        let infousuario = await info_perfil();

        if (infousuario.code == 0) {
            
            let datos = infousuario.data.items[0];
            let inputs = this.state.inputsform;

            inputs[0].valor = datos.nombre;
            inputs[1].valor = datos.apellido;
            inputs[2].valor = datos.email;
            inputs[3].valor = datos.login;
            inputs[4].valor = datos.tipo;

            inputs[0].validacion = "";
            inputs[1].validacion = "";
            inputs[2].validacion = "";
            inputs[3].validacion = "";
            inputs[4].validacion = "";

            this.setState({ inputsform: inputs });
        }
        else {
            if (infousuario.code == 1) {
                toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (infousuario.code == 2) {
                toast.warn(infousuario.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);

    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        this.refresh();
    }

    guardarcambios = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        let respuestavalidacion = await this.validarcontenido(this.state.inputsform);
        if (respuestavalidacion.aprobado) {
            this.finalizaraccion()
        }
        else {
            this.setState({ loadingImage: false })
        }
    }

    validarcontenido = async (contenido) => {
        let respuestavalidacion = true;

        let nombreguardado = validarpersonanombre(contenido[0].valor)
        contenido[0].mensajevalidacion = nombreguardado.mensaje;
        contenido[0].validacion = nombreguardado.codigo;
        if (nombreguardado.codigo == "invalid")
            respuestavalidacion = false;

        let apellidoguardado = validarpersonanombre(contenido[1].valor)
        contenido[1].mensajevalidacion = apellidoguardado.mensaje;
        contenido[1].validacion = apellidoguardado.codigo;
        if (apellidoguardado.codigo == "invalid")
            respuestavalidacion = false;

        let correoguardado = validarEmail(contenido[2].valor)
        contenido[2].mensajevalidacion = correoguardado.mensaje;
        contenido[2].validacion = correoguardado.codigo;
        if (correoguardado.codigo == "invalid")
            respuestavalidacion = false;

        let loginguardado = validarpersonanombre(contenido[3].valor)
        contenido[3].mensajevalidacion = loginguardado.mensaje;
        contenido[3].validacion = loginguardado.codigo;
        if (loginguardado.codigo == "invalid")
            respuestavalidacion = false;

        if (contenido[4].contenido == "") {
            toast.warn("Debes seleccionar un perfil", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            respuestavalidacion = false;
        }

        if (respuestavalidacion == true) {
            respuestavalidacion = await this.enviardatos(contenido);

            if (respuestavalidacion == false) {
                setTimeout(() => {
                    this.setState({ loadingImage: false })
                }, 200);
            }
        }
        else {
            this.setState({ loadingImage: false })
        }

        return { aprobado: respuestavalidacion, nuevoarreglo: contenido }
    }

    finalizaraccion = async () => {
        this.refresh()
    }

    enviardatos = async (datos) => {

        let nombre = datos[0].valor;
        let apellido = datos[1].valor;
        let email = datos[2].valor;
        let login = datos[3].valor;
        let idusuariotipo = datos[4].contenido;
        let idestatus = 1
        let enviado = null;

        enviado = await gestion_editarusuario(login, nombre, apellido, email, idusuariotipo, idestatus, 1);

        if (enviado.code == 0) {
            toast.success("Usuario guardado exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }
        else {
            if (enviado.code == 1) {
                toast.warn("No se pudo guardar, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (enviado.code == 2) {
                toast.warn(enviado.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
            return false
        }
    }



    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);

    }



    guardarvalor = (item) => {

        let listainputs = this.state.inputsform
        listainputs.forEach(input => {
            if (input.posicion == item.posicion)
                input.valor = item.valor;
        });
        this.setState({ inputsform: listainputs })
    }

    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                {(this.state.cancelar == true) ? (
                    <Redirect to="/" />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px", minWidth: "1100px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body " style={{ minWidth: "400px", maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}>

                        <div className="panel-body-header-table" style={{ minWidth: "300px", minHeight: "70px", maxHeight: "100px" }}>
                            {this.state.titulotabla}
                            <div className="panel-body-header-table-buttons ">
                                <div>
                                    <Button className="greenoutlinebutton" onClick={this.refresh}><RiRefreshLine /> Refrescar</Button>
                                </div>
                            </div>
                        </div>



                        {this.state.inputsform.map((item, index) => (
                            <>
                                {(item.tipoinput == "vista") ? (
                                    <div className="modal-catalogo-vista-tabla" style={(index % 2 == 0) ? ({ background: "#e9ecef" }) : { background: "#e9ecef" }}>
                                        <label>{item.tituloinput} </label>
                                        <div>{item.valor}</div>
                                    </div>
                                ) : null}

                                {(item.tipoinput == "text" || item.tipoinput == "textarea") ? (
                                    <>
                                        <Inputform datosinput={item} valoringresado={(e) => this.guardarvalor(e)} />
                                        <br />
                                    </>
                                ) : null}

                            </>
                        ))}


                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :

                            <>
                                {(this.state.showmensaje) ? (
                                    <AreaMensajes
                                        showmensaje={this.state.showmensaje}
                                        titulo={this.state.titulo}
                                        mensaje={this.state.mensaje} />
                                ) : null}
                                <br />

                                <div className="modal-catalogo-checkinput" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar envío de formulario
                                </div>
                                <div className="modal-catalogos-buttons">
                                    <Button className="cancelbutton" onClick={() => this.setState({ cancelar: true })}><GoX /> Cancelar</Button>
                                    <Button disabled={!this.state.confirmarenvio} className="bluebutton" onClick={() => this.guardarcambios()}> <BsCheckLg /> Guardar</Button>
                                </div>
                            </>
                        }


                    </div>

                    <div className="panel-footer">
                        <label>  © VECTOR, Casa de Bolsa. Derechos Reservados. 2024</label>
                    </div>
                </div>
            </>
        );
    }
}