import React from "react";
import { ClockLoader, PulseLoader } from "react-spinners";
import "./SpinnerModal.css"


export default class SpinnerModal extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <div className="newspiner">
                    <main className='mainspinners'>
                        <img className='lagospiner' loading="lazy" src="./assets/logos/empresa_logo_spin.png" alt="" />
                        <div>
                        <ClockLoader 
                            color={"#ffffff"}
                            size={60}
                            loading={this.props.show}
                            css="display: block; margin:auto;"
                        />
                        </div>
                    </main>
                </div>
            </>
        )
    }
}