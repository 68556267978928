import React from "react";
import Nabvar, { convertirtiempo, iniciarcuentaregresiva } from "../Panel/Nabvar";

import Inputform from "../Inputform/Inputform";
import { RiLoginCircleLine } from 'react-icons/ri';
import { login } from "../../Services/Api";
import { validarpassword, validaruser } from "../../Services/Validaciones";
import { Button } from "reactstrap";
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import AreaMensajes from "../Modales/AreaMensajes";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight - 1,
            anchura: window.innerWidth,
            inputusuario: {
                tituloinput: "Usuario:", contenido: "", tipoinput: "text", valor: "", posicion: 1,
                validacion: "", mensajevalidacion: " Error de usuario", disabled: false
            },
            inputpassword: {
                tituloinput: "Contraseña:", contenido: "", tipoinput: "password", valor: "", posicion: 2,
                validacion: "", mensajevalidacion: "Error de contraseña", disabled: false
            },
            loadingImage: false,
            login: false,
            inputactual: null,
            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: ""
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight -1, anchura: window.innerWidth })
    }

    guardarvalor = (item) => {
        let nuevoinput = (item.posicion == 1) ? (this.state.inputusuario) : this.state.inputpassword;
        nuevoinput.valor = item.valor;
        if (item.posicion == 1)
            this.setState({ inputusuario: nuevoinput })
        else
            this.setState({ inputpassword: nuevoinput })
    }

    login = async () => {
        this.setState({ loadingImage: true, showmensaje: false })
        let user = this.state.inputusuario;
        let password = this.state.inputpassword;
        let usercorrecto = validaruser(user.valor);
        let passwordcorrecto = validarpassword(password.valor);
        let aprobado = true;

        user.mensajevalidacion = usercorrecto.mensaje;
        user.validacion = usercorrecto.codigo;
        password.mensajevalidacion = passwordcorrecto.mensaje;
        password.validacion = passwordcorrecto.codigo;

        this.setState({ inputusuario: user, inputpassword: password });

        if (usercorrecto.codigo == "invalid" || passwordcorrecto.codigo == "invalid") {
            aprobado = false;
            this.setState({ loadingImage: false })
        }

        else {
            let xlogin = await login(user.valor, password.valor);
            if (xlogin.code == 0) {
                let user = xlogin.data[0];
                sessionStorage.setItem("ls", [user.email, user.apellido, user.nombre, user.token]);
                this.setState({ login: true, loadingImage: false })
                let ultimomovimiento = new Date().toLocaleTimeString();
                sessionStorage.setItem("ultimomovimiento", convertirtiempo(ultimomovimiento));
                window.addEventListener("mousemove", function () {
                    let ultimomovimiento = new Date().toLocaleTimeString();
                    sessionStorage.setItem("ultimomovimiento", convertirtiempo(ultimomovimiento));

                });
                window.addEventListener("click", function () {
                    let ultimomovimiento = new Date().toLocaleTimeString();
                    sessionStorage.setItem("ultimomovimiento", convertirtiempo(ultimomovimiento));
                });
                window.addEventListener("keypress", function () {
                    let ultimomovimiento = new Date().toLocaleTimeString();
                    sessionStorage.setItem("ultimomovimiento", convertirtiempo(ultimomovimiento));
                });

                iniciarcuentaregresiva();
            }
            else {

                if (xlogin.code == 1) {
                    toast.error("¡El inicio de sesión ha fallado! Por tu seguridad cuentas con tres intentos para introducir tu contraseña, posteriormente será bloqueada y deberás comunicarte con tu Ejecutivo de Relación para definir una nueva contraseña.", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
                    setTimeout(() => {
                        this.setState({ loadingImage: false })
                    }, 200);
                }
                else if (xlogin.code == 2) {
                    toast.warn(xlogin.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
                else {
                    toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
                }

            }
        }
    }

    mensaje(tipo, titulo, mensaje) {

        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false, tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        }, 200);

    }

    onKeyUp = (event) => {
        if (event.charCode === 13) {
            this.login();
        }
    }


    render() {
        return (
            <>

                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px", minWidth: "1100px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body " style={{overflow: "auto"}}>


                        {(sessionStorage.getItem("ls")) ? (

                            <div className="login-content">
                                <p className="home-title">Bienvenido al Sistema de Gestión Vector, Casa de Bolsa</p>
                            </div>
                        ) :
                            <div className="login-content">

                                <div className="login-content-aux">
                                    <p className="home-title">Bienvenido al Sistema de Gestión Vector, Casa de Bolsa</p>
                                    <div className="login-form">
                                        {(this.state.showmensaje) ? (
                                            <AreaMensajes
                                                showmensaje={this.state.showmensaje}
                                                titulo={this.state.titulomensaje}
                                                mensaje={this.state.descripcionmensaje} />
                                        ) : null}


                                        <Inputform datosinput={this.state.inputusuario} valoringresado={(e) => this.guardarvalor(e)} />
                                        <br />
                                        <Inputform onKeyUp={this.onKeyUp}
                                            datosinput={this.state.inputpassword} valoringresado={(e) => this.guardarvalor(e)} />
                                        <br />

                                        <Button
                                            onClick={() => this.login()} className="button-login bluebutton">
                                            <RiLoginCircleLine className="login-icon" />
                                            Ingresar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>

                    <div className="panel-footer">
                        <label> © VECTOR, Casa de Bolsa. Derechos Reservados. 2024</label>
                    </div>
                </div>
            </>
        )
    }
}

