import React from "react"
import { GoX } from "react-icons/go";
import { Button, Modal, ModalBody, Table } from "reactstrap";


export default class ModalDetallesNotificacion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            header2: [
                { titulo: "TIPO DE OPERACIÓN", longitud: "400px" },
                { titulo: "COMPRA DE DIVISAS", longitud: "400px" },
            ],

            tabla: [
                { titulo: "Empresa", dato: this.props.listamodal.empresa },
                { titulo: "Folio", dato: this.props.listamodal.folio },
                { titulo: "Fecha", dato: this.props.listamodal.fecha },
                { titulo: "Contrato", dato: this.props.listamodal.contrato },
                { titulo: "Cliente", dato: this.props.listamodal.cliente },
                { titulo: "Tipo Notificación", dato: this.props.listamodal.tiponotificacion },
                { titulo: "Divisas", dato: this.props.listamodal.divisa },
                { titulo: "Cantidad Divisas", dato: this.props.listamodal.cantidaddivisas },
                { titulo: "Importe Divisa", dato: this.props.listamodal.importedivisa },
                { titulo: "Comisión", dato: this.props.listamodal.comision },
                { titulo: "IVA Comisión", dato: this.props.listamodal.ivacomision },
                { titulo: "Importe Operación", dato: this.props.listamodal.importeoperacion },
                { titulo: "Correo Electrónico", dato: this.props.listamodal.correo },
                { titulo: "Estatus Correo", dato: this.props.listamodal.estatuscorreo },

            ]

        }

    }

    componentDidMount() {
        window.addEventListener("resize", this.alturacontenedor)
        setTimeout(() => {
            this.alturacontenedor()
        }, 300);
    }

    alturacontenedor() {
        let item = document.querySelector(".modal-body");
        let header = document.querySelector(".modal-titulo").getBoundingClientRect().height;
        let nuevaaltura = item.getBoundingClientRect().height;
        let calcular = nuevaaltura - header - 50;
        document.querySelector(".modal-bitacora-cuerpocontenido").style.height = calcular + "px"
    }

    showmodal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.changestate();
        }, 200);
    }


    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
    }


    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered
                    className=" modal-cuentas modal-bitacora ">
                    <ModalBody style={{ height: "100%" }}>
                        <div style={{ height: "100%", width: "100%" }}>
                            <p className="modal-titulo">
                                <label className="modal-titulo-aux">{this.props.modaltitulo}</label>
                                <label className="modal-cuentas-cerrar">
                                    <Button className="greenbutton" onClick={() => this.showmodal()}><GoX /> </Button>
                                </label>
                            </p>

                            <div className="modal-bitacora-cuerpocontenido">
                                <div className="modal-bitacora-cuerpocontenido-tabla" style={(this.props.opcion == 1) ? ({ minWidth: "500px", fontSize: "13px" }) : { width: "auto", fontSize: "13px" }}>
                                    <div className="modal-bitacora-flex modal-bitacora-header">
                                        {this.state.header2.map(header => (
                                            <div className="modal-bitacora-th" style={{ width: header.longitud }}>
                                                {header.titulo}
                                            </div>
                                        ))}
                                    </div>

                                    {this.state.tabla.map(linea => (<>
                                        <div className="modal-bitacora-flex">
                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[0].longitud }}>
                                                {linea.titulo}
                                            </div>
                                            <div className="modal-bitacora-td" style={{ width: this.state.header2[1].longitud }}>
                                                {linea.dato}
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: "0px", marginBottom: "0px" }} />

                                    </>))}

                                </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

