import React from "react";
import { Redirect } from "react-router-dom";
import {
    administracion_bitacora, 
    administracion_cuentas,
    catalogo_clientes,
    catalogo_tiponotificaciones,
    ctalogo_promotores
} from "../../Services/Api";
import ModalDetallesNotificacion from "../Modales/ModalDetallesNotificacion";
import BitacoragestionFiltros from "./Complementos/BitacoragestionFiltros";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import AreaMensajes from "../Modales/AreaMensajes";
import { Button, Table } from "reactstrap";
import { FaSort } from "react-icons/fa";
import Paginacion, { buildContent } from "../Panel/Paginacion";
import Nabvar from "../Panel/Nabvar";
import { BsEyeFill } from "react-icons/bs";
import { ordenarbitacoragestion } from "./Complementos/CuentasOrdenar";

export default class BitacoraNotificacion extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            altura: window.innerHeight - 1,
            anchura: window.innerWidth,
            titulotabla: "Bitácora de Notificación",
            tabla: {
                encabezados: [
                    "ID",
                    "Empresa",
                    "Folio",
                    "Fecha ",
                    "Contrato",
                    "Cliente",
                    "Tipo de Notificación",
                    "Estatus",
                    "Divisa",
                    "Importe"
                ],
                dimensiones: [
                    "50px",
                    "80px",
                    "120px",
                    "80px",
                    "110px",
                    "100px",
                    "100px",
                    "70px",
                    "70px",
                    "90px"
                ],

                elementos: [],
            },
            clientes: [],
            notificaciones:[],
            promotores:[],
            listamodal: [],
            modalelementos: false,
            tipoaccion: null,
            jsoncsv: [],

            //paginacion
            activePage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            loadingImage: true,
            actualordenado: "",
            tipomensaje: 1,

            showmensaje: false,
            titulomensaje: "",
            descripcionmensaje: "",
            showmensajemodal: false,
            titulomensajemodal: "",
            descripcionmensajemodal: ""
        }
    }



    componentWillUnmount() {
        window.removeEventListener("resize", this.dimensiones)
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    refreshInfo = async () => {
        console.log("refresh")
        this.setState({ loadingImage: true, showmensaje: false })


        const lista = await administracion_cuentas();
        if (this.manejadorLlamadasApis(lista)) {
            return
        }

             //llamar catálogos para filtros clientes, tipo notificacion, promotor, compañía
        const respclientes = await catalogo_clientes();
        if (this.manejadorLlamadasApis(respclientes)) {
            return
        }

        const resptiponotificacion = await catalogo_tiponotificaciones();
        if (this.manejadorLlamadasApis(resptiponotificacion)) {
            return
        }

        const resppromotores = await ctalogo_promotores();
        if (this.manejadorLlamadasApis(resppromotores)) {
            return
        }


        let listaitems = this.state.clientes;
        listaitems = lista.data.items;
        this.setState({ 
            clientes: respclientes.data.items, 
            notificaciones: resptiponotificacion.data.items,
            promotores: resppromotores.data.items,
             actualordenado: "", showmensaje: false });

        if (lista.data.sololectura == 1) {
            this.setState({ permisoedicion: false });
        }
        else {
            this.setState({ permisoedicion: true });
        }

        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    manejadorLlamadasApis(respuesta) {
        console.log("llamadasapis", respuesta)
        if (respuesta.code == 0) {
            return false
        }
        else if (respuesta.code == 1) {
            this.setState({ loadingImage: false })
            toast.warn("Parece que tu sesión ha caducado, vuelve a ingresar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }
        else if (respuesta.code == 2) {
            toast.warn(respuesta.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingImage: false })
            return true
        }
        else {
            toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            this.setState({ loadingImage: false })
            return true
        }


    }


    async componentDidMount() {
        window.addEventListener("resize", this.dimensiones)
        this.refreshInfo()
    }


    async buscarseleccionado(seleccionado) {
        this.setState({ loadingImage: true, showmensaje: false })
        let bitacora = await administracion_bitacora(seleccionado[2], seleccionado[0], seleccionado[1]);


        if (bitacora.code == 0) {
            let nuevatabla = this.state.tabla;
            nuevatabla.elementos = bitacora.data.items;


            let date = new Date();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let fechaaux = null;

            if (month < 10) {
                fechaaux = (`${day}/0${month}/${year}`)
            } else {
                fechaaux = (`${day}/${month}/${year}`)
            }


            var temarray = bitacora.data.items;

            var array_datos = [];
            var array_subdatos = [];


            array_datos = ["Fecha de gestión", "Cliente", "Fecha inicio", "Fecha Fin"];
            array_subdatos.push(array_datos);

            array_datos = [fechaaux, seleccionado[3],
                seleccionado[0], seleccionado[1]];
            array_subdatos.push(array_datos);
            array_subdatos.push([""]);

            var arrayheders = JSON.parse(JSON.stringify(this.state.tabla.encabezados));
            arrayheders.pop();
            array_subdatos.push(arrayheders);



            for (let i = 0; i < temarray.length; i++) {

                array_datos = [temarray[i].id,
                temarray[i].cuenta,
                temarray[i].cliente,
                temarray[i].medio,
                temarray[i].servicio,
                temarray[i].archivo,
                temarray[i].fecharecepcion,
                temarray[i].totaltransacciones,
                temarray[i].totaltransaccioneserror,
                temarray[i].totaltransaccionesenviadas,
                temarray[i].totaltransaccionesenviadaserror,
                temarray[i].fechavalidacion,
                temarray[i].fechanotificacioncliente,
                temarray[i].codigoestructura,
                temarray[i].estatus

                ]
                array_subdatos.push(array_datos)

            }

            if (array_subdatos.length > 3) {

                this.setState({ jsoncsv: array_subdatos })
            }





            //paginarlista         
            this.setState({ tabla: nuevatabla });
            this.handleCommentsChange(1)
        } else {
            if (bitacora.code == 1) {
                toast.warn("Ha ocurrido un problema, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (bitacora.code == 2) {
                toast.warn(bitacora.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
            }
        }
        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);
    }


    cerrarmodalcatalogo = () => {
        this.setState({ modalelementos: false });
    }


    mensaje(tipo, titulo, mensaje) {
        this.setState({ tipo: tipo, titulomensaje: titulo, descripcionmensaje: mensaje })
        setTimeout(() => {
            this.setState({ showmensaje: true, loadingImage: false })
        }, 200);
    }


    handleCommentsChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
            listapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activePage: pageNumber,
                listapaginada: buildContent(this.state.itemsperpage, pageNumber, this.state.tabla.elementos)
            });
        }, 0);

    }


    crearelemento = async (origen) => {
        this.setState({ loadingImage: true, showmensaje: false })
        let tituloactivo = "Detalle de Notificación";

        //llenar la tabla 
        let lista = {
            empresa: "DIVISAS",
            folio: "987654321 ",
            fecha: "30/03/2024",
            contrato: "1234567890",
            cliente: "NOMBRE COMPLETO DEL CLIENTE",
            tiponotificacion: "COMPRA DE DIVISAS",
            divisa: "Dolares americanos",
            cantidaddivisas: "1800",
            importedivisa: "17.68",
            comision: "0.00",
            ivacomision: "0.00",
            importeoperacion: "31,824.00",
            correo: "correodecliente@cc.com",
            estatuscorreo: "CORREO ENVIADO"


        }
        //await administracion_bitacora_desglose(origen[1]);
        // if (lista.code == 0) {
        this.setState({ tipoaccion: origen, modalelementos: !this.state.modalelementos, opcion: origen[0], modaltitulo: tituloactivo, listamodal: lista });
        /* }
         else {
             if (lista.code == 1) {
                 toast.warn("Ha ocurrido un problema, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
             }
             else if (lista.code == 2) {
                 toast.warn(lista.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
             }
             else {
                 toast.error("Revisa tu conexión a internet", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                 this.mensaje(0, "Sin conexión", "Revisa tu conexión a internet")
             }
         }*/
        setTimeout(() => {
            this.setState({ loadingImage: false })
        }, 200);

    }


    ordenar = (opcion) => {
        let actual = this.state.tabla;
        let x = ordenarbitacoragestion(opcion, actual, this.state.actualordenado);
        actual.elementos = x;
        this.setState({ tabla: actual, actualordenado: opcion });
        this.handleCommentsChange(this.state.activePage)
    }



    render() {
        return (
            <>
                {(this.state.loadingImage == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingImage} />
                ) : null}
                {(sessionStorage.getItem("ls") == null) ? (
                    <Redirect to="/" />
                ) : null}

                {(this.state.modalelementos) ? (
                    <ModalDetallesNotificacion
                        show={this.state.modalelementos}
                        modaltitulo={this.state.modaltitulo}
                        listamodal={this.state.listamodal}
                        changestate={() => this.cerrarmodalcatalogo()} />
                ) : null}

                <ToastContainer />

                <div className="panel-background-container"
                    style={(this.state.anchura > 1100) ? ({ height: this.state.altura + "px" }) : { height: this.state.altura + "px" }}>
                    <Nabvar />
                    <div className="panel-body panel-bitacora ">

                        <div className="panel-body-header-table" style={{ height: "initial", display: "block", minWidth: "450px" }}>
                            {this.state.titulotabla}
                            {(this.state.clientes.length > 0) ? (
                                <BitacoragestionFiltros arraycsv={this.state.jsoncsv} totalitems={this.state.listapaginada}
                                    listaclientes={this.state.clientes} 
                                    listapromotores={this.state.promotores}
                                    listanotificaciones={this.state.notificaciones}
                                    array_heders={this.state.tabla.encabezados}
                                    buscarseleccionado={(e) => this.buscarseleccionado(e)} refreshInfo={this.refreshInfo} />
                            ) : null
                            }


                        </div>

                        {(this.state.showmensaje) ? (
                            <AreaMensajes
                                showmensaje={this.state.showmensaje}
                                titulo={this.state.titulomensaje}
                                mensaje={this.state.descripcionmensaje} />
                        ) :

                            <>

                                {(this.state.tabla.elementos.length > 0) ? (
                                    <>
                                        <Table className="panel-table" >
                                            <thead style={{ verticalAlign: "top" }}>
                                                <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tablaitemhead" + index} className={"panel-table-headcell" + index}
                                                            style={{ minWidth: this.state.tabla.dimensiones[index], fontSize: "13px" }}
                                                            onClick={() => this.ordenar(index)}>
                                                            {titulo} <FaSort className={(this.state.actualordenado == index) ? ("tables-iconsort tables-actualsorting") : "tables-iconsort"} />
                                                        </th>
                                                    ))}
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.listapaginada.map((item, index) => (
                                                    <tr key={"tablaitemtr" + index} style={{ fontSize: "13px" }}>
                                                        <td className={"panel-table-cell" + 0}>
                                                            {item.id}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.cuenta}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.cliente}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.medio}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.cliente}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.archivo}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.fecharecepcion}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.estatus}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.totaltransaccioneserror}
                                                        </td>
                                                        <td className={"panel-table-cell" + 3}>
                                                            {item.totaltransaccionesenviadas}
                                                        </td>







                                                        <td className={"panel-table-cell" + 3}>
                                                            <div style={{ width: "29px", height: "29px", margin: 'auto' }}>
                                                                <Button className="greenbutton" style={{ width: "29px", height: "29px", border: 'none' }} onClick={() => this.crearelemento([2, item.id])}><BsEyeFill style={{ marginTop: '-4' }} /></Button>
                                                            </div>

                                                        </td>


                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>


                                    </>
                                ) :
                                    <label><b>Esta tabla no tiene datos</b></label>
                                }



                                <div className="panel-pagination-section">
                                    <Paginacion
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsperpage}
                                        totalItemsCount={this.state.tabla.elementos}
                                        pageRangeDisplayed={this.state.rangepages}
                                        handleCommentsChange={(e) => this.handleCommentsChange(e)}
                                    />
                                </div>
                            </>
                        }

                    </div>

                    <div className="panel-footer">
                        <label>  © VECTOR, Casa de Bolsa. Derechos Reservados. 2024</label>
                    </div>
                </div>
            </>
        );
    }
}