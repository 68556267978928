export function ordenarsituacion(opcion, tabla, actualordenado) {
    //console.log(opcion, tabla, actualordenado)
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogoestatus) > parseInt(b.idcatalogoestatus)) {
                    return 1;
                }
                if (parseInt(a.idcatalogoestatus) < parseInt(b.idcatalogoestatus)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.clave.toUpperCase() > b.clave.toUpperCase()) {
                    return 1;
                }
                if (a.clave.toUpperCase() < b.clave.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista
    }

}

export function ordenarNotificaciones(opcion, tabla, actualordenado) {
    //console.log(opcion, tabla, actualordenado)
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogoestatus) > parseInt(b.idcatalogoestatus)) {
                    return 1;
                }
                if (parseInt(a.idcatalogoestatus) < parseInt(b.idcatalogoestatus)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.clave.toUpperCase() > b.clave.toUpperCase()) {
                    return 1;
                }
                if (a.clave.toUpperCase() < b.clave.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista
    }

}


export function ordenarenvioresultados(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogoenviarresultados) > parseInt(b.idcatalogoenviarresultados)) {
                    return 1;
                }
                if (parseInt(a.idcatalogoenviarresultados) < parseInt(b.idcatalogoenviarresultados)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.descripcion.toUpperCase() > b.descripcion.toUpperCase()) {
                    return 1;
                }
                if (a.descripcion.toUpperCase() < b.descripcion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}







export function ordenarservicios(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogoservicios) > parseInt(b.idcatalogoservicios)) {
                    return 1;
                }
                if (parseInt(a.idcatalogoservicios) < parseInt(b.idcatalogoservicios)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.servicio.toUpperCase() > b.servicio.toUpperCase()) {
                    return 1;
                }
                if (a.servicio.toUpperCase() < b.servicio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.carpetaentrada.toUpperCase() > b.carpetaentrada.toUpperCase()) {
                    return 1;
                }
                if (a.carpetaentrada.toUpperCase() < b.carpetaentrada.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.carpetasalida.toUpperCase() > b.carpetasalida.toUpperCase()) {
                    return 1;
                }
                if (a.carpetasalida.toUpperCase() < b.carpetasalida.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.rutahome.toUpperCase() > b.rutahome.toUpperCase()) {
                    return 1;
                }
                if (a.rutahome.toUpperCase() < b.rutahome.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}












export function ordenarmonedas(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogomonedas) > parseInt(b.idcatalogomonedas)) {
                    return 1;
                }
                if (parseInt(a.idcatalogomonedas) < parseInt(b.idcatalogomonedas)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.codigo.toUpperCase() > b.codigo.toUpperCase()) {
                    return 1;
                }
                if (a.codigo.toUpperCase() < b.codigo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.descripcion.toUpperCase() > b.descripcion.toUpperCase()) {
                    return 1;
                }
                if (a.descripcion.toUpperCase() < b.descripcion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}














export function ordenarerrores(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogoerrores) > parseInt(b.idcatalogoerrores)) {
                    return 1;
                }
                if (parseInt(a.idcatalogoerrores) < parseInt(b.idcatalogoerrores)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.codigo.toUpperCase() > b.codigo.toUpperCase()) {
                    return 1;
                }
                if (a.codigo.toUpperCase() < b.codigo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.descripcion.toUpperCase() > b.descripcion.toUpperCase()) {
                    return 1;
                }
                if (a.descripcion.toUpperCase() < b.descripcion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}























export function ordenarmedioservicios(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogocanalarchivos) > parseInt(b.idcatalogocanalarchivos)) {
                    return 1;
                }
                if (parseInt(a.idcatalogocanalarchivos) < parseInt(b.idcatalogocanalarchivos)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.canal.toUpperCase() > b.canal.toUpperCase()) {
                    return 1;
                }
                if (a.canal.toUpperCase() < b.canal.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.descripcion.toUpperCase() > b.descripcion.toUpperCase()) {
                    return 1;
                }
                if (a.descripcion.toUpperCase() < b.descripcion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}




































export function ordenarpromotores(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogoasesores) > parseInt(b.idcatalogoasesores)) {
                    return 1;
                }
                if (parseInt(a.idcatalogoasesores) < parseInt(b.idcatalogoasesores)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.nombre.toUpperCase() > b.nombre.toUpperCase()) {
                    return 1;
                }
                if (a.nombre.toUpperCase() < b.nombre.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.correo.toUpperCase() > b.correo.toUpperCase()) {
                    return 1;
                }
                if (a.correo.toUpperCase() < b.correo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.servicio.toUpperCase() > b.servicio.toUpperCase()) {
                    return 1;
                }
                if (a.servicio.toUpperCase() < b.servicio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}







export function ordenarfamilias(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogofamilias) > parseInt(b.idcatalogofamilias)) {
                    return 1;
                }
                if (parseInt(a.idcatalogofamilias) < parseInt(b.idcatalogofamilias)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.familia.toUpperCase() > b.familia.toUpperCase()) {
                    return 1;
                }
                if (a.familia.toUpperCase() < b.familia.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
       
       

        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}









export function ordenartipooperacion(opcion, tabla, actualordenado) {
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogotipooperacion) > parseInt(b.idcatalogotipooperacion)) {
                    return 1;
                }
                if (parseInt(a.idcatalogotipooperacion) < parseInt(b.idcatalogotipooperacion)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.folio.toUpperCase() > b.folio.toUpperCase()) {
                    return 1;
                }
                if (a.folio.toUpperCase() < b.folio.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.descripcion.toUpperCase() > b.descripcion.toUpperCase()) {
                    return 1;
                }
                if (a.descripcion.toUpperCase() < b.descripcion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }


        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.tipo.toUpperCase() > b.tipo.toUpperCase()) {
                    return 1;
                }
                if (a.tipo.toUpperCase() < b.tipo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }


        else if (opcion == 4) {
            lista = temporal.sort(function (a, b) {
                if (a.codigo.toUpperCase() > b.codigo.toUpperCase()) {
                    return 1;
                }
                if (a.codigo.toUpperCase() < b.codigo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

       
       

        else if (opcion == 5) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }

        return lista;
    }


}





export function ordenarestadodecuenta(opcion, tabla, actualordenado) { ///   <------- Temporarl estado de cuenta
    let actual = tabla;
    let temporal = [];
    let lista = [];
    actual.elementos.forEach(el => {
        temporal.push(el);
    });


    if (actualordenado == opcion) {
        lista = temporal.reverse();
        return lista
    }
    else {

        if (opcion == 0) {
            lista = temporal.sort(function (a, b) {
                if (parseInt(a.idcatalogoestadodecuenta) > parseInt(b.idcatalogoestadodecuenta)) {
                    return 1;
                }
                if (parseInt(a.idcatalogoestadodecuenta) < parseInt(b.idcatalogoestadodecuenta)) {
                    return -1;
                }

                return 0;
            });
        }
        else if (opcion == 1) {
            lista = temporal.sort(function (a, b) {
                if (a.tipo.toUpperCase() > b.tipo.toUpperCase()) {
                    return 1;
                }
                if (a.tipo.toUpperCase() < b.tipo.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 2) {
            lista = temporal.sort(function (a, b) {
                if (a.descripcion.toUpperCase() > b.descripcion.toUpperCase()) {
                    return 1;
                }
                if (a.descripcion.toUpperCase() < b.descripcion.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
        else if (opcion == 3) {
            lista = temporal.sort(function (a, b) {
                if (a.estatus.toUpperCase() > b.estatus.toUpperCase()) {
                    return 1;
                }
                if (a.estatus.toUpperCase() < b.estatus.toUpperCase()) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
       

        return lista;
    }


}




